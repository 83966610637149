import React, { useEffect } from 'react'
import pageContext from './pageContext.json'
import Layout from 'src/layouts/BaseLayout'
import { Container } from './style'
import { initTheme, Theme } from '@interco/inter-ui'
import Faq from './sections/faq/_index'
import Hero from './sections/hero/_index'
import Calculator from './sections/calculator/_index'
import MoreAntecipation from './sections/more-antecipation/_index'
import TurnPointsIntoBenefits from './sections/turn-points-into-benefits/_index'

export default function AntecipacaoParcelas () {
  useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  return (
    <Layout pageContext={pageContext}>
      <Container>
        <Hero />
        <Calculator />
        <MoreAntecipation />
        <TurnPointsIntoBenefits />
        <Faq pageContext={pageContext} />
      </Container>
    </Layout>
  )
}
