import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

type CardProps = {
  color: string;
}

export const Section = styled.section`
  background-color: #FDF8EE;

  h3 {
    font-family: 'Citrina';
  }

`
export const Card = styled.div<CardProps>`
  background-color: ${(props: CardProps) => props.color ?? props.color };
  border-radius: 20px;
  padding: 19px 29px;
  width: 222px;
  height: 124px;
  margin-bottom:  32px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;



  @media (min-width: ${breakpoints.md}) {
    height: 120px;
    width: 100%;
    border-radius: 12px;
  }

  @media (min-width: ${breakpoints.lg}) {
    height: 124px;
    border-radius: 12px;
  }

  @media (min-width: ${breakpoints.xl}) {
    height: 200px;
    border-radius: 12px;
    width: 358px;
  }

`

export const Icon = styled.div`
  position: absolute;
  right: 25px;
  top: 10px;

`
