import React from 'react'

import cardJson from '../../assets/data/turn-points-into-benefits.json'
import useWidth from 'src/hooks/window/useWidth'
import { widths } from 'src/styles/breakpoints'

import { Section, Card, Icon } from './style'
import PlaneDeparture from '@interco/icons/core/travel-mobility/ic_plane_departure'
import ContractMoney from '@interco/icons/core/files/ic_contract_money'
import RotateMoney from '@interco/icons/core/finance/ic_rotate_money'
import Tag from '@interco/icons/core/shopping/ic_tag'
import PiggyMoney from '@interco/icons/core/finance/ic_piggy_money'
import RotateAdd from '@interco/icons/core/action-navigation/ic_rotate_add'

type CardProps = {
  icon: string;
  text: string;
  color: string;
  points?: string | null;
}

const TurnPointsIntoBenefits = () => {
  const width = useWidth(300)

  const icons = {
    plane: <PlaneDeparture height={width <= widths.md ? 23 : 37} width={width <= widths.md ? 23 : 37} color='#ffffff' />,
    contract: <ContractMoney height={width <= widths.md ? 23 : 37} width={width <= widths.md ? 23 : 37} color='#ffffff' />,
    rotate: <RotateMoney height={width <= widths.md ? 23 : 37} width={width <= widths.md ? 23 : 37} color='#ffffff' />,
    tag: <Tag height={width <= widths.md ? 23 : 37} width={width <= widths.md ? 23 : 37} color='#ffffff' />,
    piggy: <PiggyMoney height={width <= widths.md ? 23 : 37} width={width <= widths.md ? 23 : 37} color='#ffffff' />,
    rotateAdd: <RotateAdd height={width <= widths.md ? 23 : 37} width={width <= widths.md ? 23 : 37} color='#ffffff' />,
  }

  const handleIcon = (icon: string) => {
    switch (icon) {
      case 'plane':
        return icons.plane
      case 'contract':
        return icons.contract
      case 'rotate':
        return icons.rotate
      case 'tag':
        return icons.tag
      case 'piggy':
        return icons.piggy
      case 'rotateAdd':
        return icons.rotateAdd
      default:
        return icons.rotate
    }
  }

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center'>
          <div className='col-12 col-md-8 col-lg-10 col-xl-12'>
            <h2 className='fs-28 lh-33 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 text-grayscale--500 fw-500 mb-4 text-center'>
              Transforme pontos em benefícios exclusivos como até:
            </h2>
            <div className='row'>
              {
                cardJson.map((item: CardProps) => (
                  <div className='col-12 col-md-6 col-lg-4' key={item.text}>
                    <Card color={item.color}>
                      <Icon>
                        {handleIcon(item.icon)}
                      </Icon>
                      <h3 className='fs-17 lh-20 fs-md-16 lh-md-20 fs-xl-28 lh-xl-33 text-white fw-700 mb-0'>{item.points}</h3>
                      <h3 className='fs-17 lh-20 fs-md-16 lh-md-20  fs-xl-28 lh-xl-33 text-white fw-500 mb-0'>{item.text}</h3>
                    </Card>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default TurnPointsIntoBenefits
