import React from 'react'
import { HeroContainer, ImageContainer } from './style'
import ImageWebp from 'src/components/ImageWebp'
import ScrollTo from 'src/components/ScrollTo'

export default function Hero () {
  return (
    <HeroContainer className='py-5'>
      <div className='container'>
        <div className='row'>
          <ImageContainer className='col-12 col-md-6 offset-xl-1 order-1 order-md-2 d-flex justify-content-center'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/credito-tata-cartoes/image.webp'
              altDescription=''
              arrayNumbers={[ ]}
              arrayNumbersHeight={[ ]}
            />
          </ImageContainer>
          <div className='col-12 col-md-6 col-xl-5 order-2 order-md-1 d-flex flex-column justify-content-center'>
            <h1 className='fs-40 fs-lg-50 lh-44 lh-lg-60 fw-400 text-grayscale--500 mt-3'>Antecipação de <strong>Parcelas</strong></h1>
            <p className='fs-14 fs-lg-16 lh-16 lh-lg-22 text-grayscale--500'>
              Até 300.000 pontos esperando por você! Quanto mais você antecipa, mais pontos Loop você acumula.
            </p>
            <ScrollTo to='#calculator-85' styles='btn bg-orange--extra text-white rounded-2 btn--lg fs-14 fw-600 text-none mt-3'>
              Calcular Pontos Extras
            </ScrollTo>
          </div>
        </div>
      </div>
    </HeroContainer>
  )
}
