import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import { Button } from '@interco/inter-ui/components/Button'

export default function MoreAntecipation () {
  return (
    <section>
      <div className='container'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-12 col-md-3 d-none d-md-flex justify-content-center'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/antecipacao-parcelas-qrcode/image.webp'
              altDescription='QR Code para antecipar parcelas no Super App'
              arrayNumbers={[ 200, 200, 200, 200 ]}
            />
          </div>
          <div className='col-12 col-md-6 col-lg-5 col-xl-4'>
            <h2 className='fs-28 lh-32 text-grayscale--500 fw-500'>
              Quanto mais parcelas você antecipa, mais pontos você acumula.
            </h2>
            <Button
              fullWidth
              as='a'
              className='btn btn--lg bg-orange--extra text-white fw-600 fs-14 lh-20 text-none mx-auto mt-3'
              href='https://intergo.app/dbaf3378'
            >
              Conferir compras parceladas
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}
