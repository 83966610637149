import { device } from 'src/styles/breakpoints'
import { sand } from 'src/styles/newColors'
import styled from 'styled-components'

export const HeroContainer = styled.section`
  background-color: ${sand};


  img {
    width: 435px;
    height: 470px;
    margin-top: 51px
  }
`

export const ImageContainer = styled.div`
  margin-top: -20px;

  @media ${device.tablet} {
    margin-bottom: -40px;
  }

  @media ${device.desktopLG} {
    margin-top: -60px;
    margin-bottom: -60px;

    img {
      margin-left: 50px;
    }
  }

  @media ${device.desktopXL} {
    margin-top: -96px;
    margin-bottom: -96px;

    img {
      margin-left: 100px;
    }
  }
`
